import React from "react";
import classes from "./NavIconDropDown.module.css";
import { Dropdown, DropdownToggle } from "reactstrap";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";

const NavIconDropDown = (props) => {
  const [hoverIcon, setHoverIcon] = React.useState(false);

  return (
    <Dropdown
      //   className="dropdown-nav"
      isOpen={props.isOpen}
      toggle={() => {
        props.toggle();
        setHoverIcon(!props.isOpen);
      }}
      onMouseEnter={() => setHoverIcon(true)}
      onMouseLeave={() => {
        !props.isOpen && setHoverIcon(false);
      }}
    >
      <CustomTooltip title={props.title} placement="bottom">
        <DropdownToggle
          color="default"
          className={classes.dropDownToggle}
          style={{
            backgroundColor: hoverIcon
              ? props.darkMode
                ? "#3A3B3C"
                : "#cccccc"
              : "transparent",
          }}
        >
          <div
            className={classes.messagesContainer}
            style={{
              borderColor: hoverIcon
                ? props.darkMode
                  ? "#3A3B3C"
                  : "#cccccc"
                : props.darkMode
                ? "white"
                : "#cccccc",
            }}
          >
            <img
              src={props.icon}
              alt="icon"
              className={`${classes.navMainIcons}`}
              width="22px"
            />
            {props.nbNotif > 0 && (
              <div className={classes.numberMessagesContainer}>
                <p className={classes.numberNotifText}>{props.nbNotif}</p>
              </div>
            )}
          </div>
        </DropdownToggle>
      </CustomTooltip>
      {props.children}
    </Dropdown>
  );
};

export default NavIconDropDown;
