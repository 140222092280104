import React from 'react';
import classes from './Signup.module.css';
import logo from "../../assets/img/logo.png";
import {Row, Col, Container} from 'reactstrap';
import jobSeeker from '../../assets/img/jobSeeker.png';
import TextField from '@material-ui/core/TextField';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../utils/axios';
import {Link} from 'react-router-dom';

const GreenCheckbox = withStyles({
    root: {
        color: '#696974',
        '&$checked': {
            color: '#1F2144',
        },
    },
    checked: {},
})((props) => <Checkbox color='default' {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        //marginTop: '20%',

        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#beb5b5',

            borderWidth: 2,
            borderRadius: 30,
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1F2144',
        },

        '& .MuiOutlinedInput-input': {
            color: '#beb5b5',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#1F2144',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },
        '& .MuiInputLabel-outlined': {
            color: '#B5B5BE',
        },

        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#1F2144',
        },
    },

    label: {
        backgroundColor: 'white',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: -6,
    },
}));

const Signup = (props) => {
    const classesMat = useStyles();
    const [checkedG, setCheckedG] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState({role: 'client'});
    const [errorMsg, setError] = React.useState(0);

    const responseGoogle = (response) => {
        console.log(response);
        // setUserInfo({...userInfo,firstName:response.profileObj.givenName,lastName:response.profileObj.familyName})
    };
    const responseFacebook = (response) => {
        console.log(response);
    };
    const signUpHandler = () => {
        console.log(userInfo)
        axios
            .post(`${process.env.REACT_APP_SI_API}/public/api/v1/register`, userInfo)
            .then((res) => {
                console.log(res);
                setError(0);
                props.history.push('/confirm?email=' + userInfo.email + '');
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 409) {
                    setError(5);
                }
            });
    };

    const validateEmail = (mail) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            mail
        );
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const nextPageHadlerAfterVerification = () => {

        if (
            !userInfo ||
            !userInfo.name ||
            !userInfo.phone ||
            !userInfo.email ||
            !userInfo.password ||
            !userInfo.confirmPassword
        ) {
            setError(1);
        } else if (!validateEmail(userInfo.email)) {
            setError(2);
        } else if (!validatePassword(userInfo.password)) {
            setError(3);
        } else if (userInfo.password !== userInfo.confirmPassword) {
            setError(4);
        } else {
            signUpHandler()
            setError(0);
        }
        console.log(userInfo);
    };
    return (
        <Container fluid className={classes.page}>
            <Row className={classes.container}>
                <Col className={classes.logoColumn}>
                    <img src={logo} alt='logo' className={classes.logo}/>
                </Col>
            </Row>

            <Row className={classes.principalContainer}>
                <Col lg='4' className={classes.col1}>
                    <img
                        src={jobSeeker}
                        className={classes.jobSeekerImg}
                        alt='jobSeeker'
                    />
                </Col>
                <Col lg='5' style={{marginBottom: '20px'}}>
                    <div className={classesMat.root}>
                        <Row className={classes.titleContainer}>
                            <h1 className={classes.pageTitle}>Register</h1>
                            <h5 className={classes.cote}>
                                Let’s Sign up first for enter into Karwisoft meet!
                            </h5>
                        </Row>
                    </div>
                    <div className={classesMat.root + ' ' + classes.col2}>
                        <Row className={classes.row}>
                            <Col xs='12' md='12' lg='12'>
                                <TextField
                                    InputLabelProps={{
                                        classes: {
                                            root: classesMat.label,
                                        },
                                    }}
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    label='Full name'
                                    className={classes.field}
                                    value={userInfo?.name || ''}
                                    onChange={(e) => {
                                        setUserInfo({...userInfo, name: e.target.value});
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={classes.row}>
                            <Col xs='12' md='6' lg='6'>
                                <TextField
                                    InputLabelProps={{
                                        classes: {
                                            root: classesMat.label,
                                        },
                                    }}
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    label='Phone Number'
                                    className={classes.field}
                                    value={userInfo?.phone || ''}
                                    onChange={(e) => {
                                        setUserInfo({...userInfo, phone: e.target.value});
                                    }}
                                />
                            </Col>

                            <Col xs='12' md='6' lg='6'>
                                <TextField
                                    InputLabelProps={{
                                        classes: {
                                            root: classesMat.label,
                                        },
                                    }}
                                    type='email'
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    label='Mail Address'
                                    className={classes.field}
                                    value={userInfo?.email || ''}
                                    onChange={(e) => {
                                        setUserInfo({...userInfo, email: e.target.value});
                                    }}
                                />
                                {errorMsg === 2 && (
                                    <p className={classes.errorTag}>
                                        *Please Enter a valid email
                                    </p>
                                )}
                            </Col>
                        </Row>

                        <Row className={classes.row}>
                            <Col xs='12' md='6' lg='6'>
                                <TextField
                                    InputLabelProps={{
                                        classes: {
                                            root: classesMat.label,
                                        },
                                    }}
                                    className={classes.field}
                                    id='outlined-password-input'
                                    label='Password'
                                    type='password'
                                    required
                                    margin='normal'
                                    autoComplete='current-password'
                                    variant='outlined'
                                    value={userInfo?.password || ''}
                                    onChange={(e) => {
                                        setUserInfo({...userInfo, password: e.target.value});
                                    }}
                                />
                            </Col>
                            <Col xs='12' md='6' lg='6'>
                                <TextField
                                    InputLabelProps={{
                                        classes: {
                                            root: classesMat.label,
                                        },
                                    }}
                                    className={classes.field}
                                    id='outlined-password-input-confirm'
                                    label='Confirm Password'
                                    type='password'
                                    margin='normal'
                                    required
                                    autoComplete='current-password'
                                    variant='outlined'
                                    value={userInfo?.confirmPassword || ''}
                                    onChange={(e) => {
                                        setUserInfo({
                                            ...userInfo,
                                            confirmPassword: e.target.value,
                                        });
                                    }}
                                />
                                {errorMsg === 4 && (
                                    <p className={classes.errorTag}>*Password does not match</p>
                                )}
                            </Col>
                        </Row>
                        <Row className={classes.row}>
                            <Col lg='12'>
                                {errorMsg === 1 && (
                                    <p className={classes.errorTagEmpty}>
                                        *Please fill the required fields
                                    </p>
                                )}
                                {errorMsg === 3 && (
                                    <p className={classes.errorTag}>
                                        *The password is at least 8 characters long, has at least
                                        one uppercase letter, has at least one lowercase letter,
                                        has at least one digit and has at least one special
                                        character
                                    </p>
                                )}
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox
                                            checked={checkedG}
                                            onChange={() => setCheckedG(!checkedG)}
                                            name='checkedB'
                                        />
                                    }
                                />

                                <span className={classesMat.agree}>
                                    I agree to Smart Interact's <b>Cookies</b> and{' '}
                                    <b>Privacy Policy</b>.
                                </span>
                            </Col>
                        </Row>
                        {/* <Row className={classes.row}>
                <Col xs='12' md='6' lg='6'>
                  <GoogleLogin
                    clientId='557229767603-p9acod06vkru08lr5agip3f3iv9ff486.apps.googleusercontent.com'
                    render={(renderProps) => (
                      <button
                        className={classes.googleButton}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <FontAwesomeIcon
                          icon={faGoogle}
                          className={classes.icon}
                          color='white'
                          size='lg'
                        />
                        Continue with Google
                      </button>
                    )}
                    buttonText='Login'
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                </Col>
                <Col xs='12' md='6' lg='6'>
                  <FacebookLogin
                    appId='188514279988993'
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className={classes.fbButton}
                      >
                        <FacebookIcon className={classes.icon}></FacebookIcon>
                        Continue with Facebook
                      </button>
                    )}
                  />
                </Col>
              </Row> */}
                        <Row className={classes.row}
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Col xs='12' md='8' lg='8'>
                                <button
                                    disabled={!checkedG}
                                    className={classes.startButton}
                                    onClick={() => {
                                        nextPageHadlerAfterVerification();
                                    }}
                                >
                                    Create an account
                                </button>
                            </Col>
                        </Row>
                        <Row className={classes.row}
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Col>
                                <h5 className={classes.loginButton}>
                                    Have an account?{' '}
                                    <Link
                                        to="/login"
                                    >
                                        login
                                    </Link>
                                </h5>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Signup;
