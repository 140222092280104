import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import classes from './ForceConfirmation.module.css';
import logo from '../../../assets/img/logo.png';
import confirmBg from '../../../assets/img/confirmBg.jpg';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import TextField from '@material-ui/core/TextField';
import axios from '../../../utils/axios';
import Swal from 'sweetalert2';

const ForceConfirmation = (props) => {
    const [emailAdr, setEmailAdr] = React.useState('');
    const verifEmailHandler = () => {
        axios
            .post(`${process.env.REACT_APP_SI_API}/public/api/v1/send-activation-link`, {
                email: emailAdr,
            })
            .then((res) => {
                console.log(res);
                Swal.fire({

                    icon: 'success',
                    title: 'Confirmation email sent successfully!',
                    showConfirmButton: false,

                });
            })
            .catch((err) => {
                console.log({ err });

                Swal.fire({

                    icon: 'error',
                    title: 'Please enter valid email!',
                    showConfirmButton: false,

                });
            });
    };

    return (
        <Container fluid>
            <Row className={classes.logoContainer}>
                <Col xs='7' md='4' lg='2'>
                    <img src={logo} alt='logo' className={classes.logo} />
                </Col>
            </Row>
            <Row className={classes.contentContainer}>
                <Col xs='11' sm='7' md='6' lg='6' className={classes.whiteSection}>
                    <Row>
                        <Col
                            xs='12'
                            md='6'
                            lg='6'
                            style={{
                                display: 'flex',
                                justifyContent: 'cente',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={confirmBg}
                                style={{ width: '100%' }}
                                alt='confirm email image'
                            />
                        </Col>
                        <Col xs='12' md='6' lg='6' className={classes.textSection}>
                            <h2 style={{ marginBottom: '30px' }}>Verify your email!</h2>
                            <p>
                                Enter your email address here, we will send you a confirmation
                                link to verify your account.
                            </p>
                            <Row style={{ marginBottom: '30px', marginTop: '10px' }}>
                                <Col xs='12' md='12' lg='8'>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label='Your Email Address'
                                        value={emailAdr}
                                        onChange={(e) => {
                                            setEmailAdr(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col xs='12' md='12' lg='4'>
                                    <button
                                        className={classes.verifyBtn}
                                        onClick={verifEmailHandler}
                                    >
                                        Verify
                                    </button>
                                </Col>
                            </Row>

                            <p className={classes.notRecived}>
                                Didn't get confirmation email?
                            </p>
                            <p className={classes.notRecived}>
                                check your spam folder or{' '}
                                <AutorenewIcon style={{ color: '#1F2144' }} />
                                <span
                                    style={{ color: '#1F2144', cursor: 'pointer' }}
                                    onClick={verifEmailHandler}
                                >
                                    Send again
                                </span>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default ForceConfirmation;
