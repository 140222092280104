import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import classes from './ForgotPassword.module.css';
import logo from '../../assets/img/logo.png';
import confirmBg from '../../assets/img/forgotBg.jpg';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import TextField from '@material-ui/core/TextField';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';

const ForgotPassword = (props) => {
    const [emailAdr, setEmailAdr] = React.useState('');
    const forgotPasswordHandler = () => {
        axios
            .post('/user/forgetpass-mail', {
                email: emailAdr,
            })
            .then((res) => {
                console.log(res);
                Swal.fire({

                    icon: 'success',
                    title: 'Instructions email sent successfully!',
                    showConfirmButton: false,

                });
            })
            .catch((err) => {
                console.log({ err });
                Swal.fire({

                    icon: 'error',
                    title: 'Please enter valid email!',
                    showConfirmButton: false,

                });
            });
    };

    return (
        <Container fluid>
            <Row className={classes.logoContainer}>
                <Col xs='7' md='4' lg='2'>
                    <img src={logo} alt='logo' className={classes.logo} />
                </Col>
            </Row>
            <Row className={classes.contentContainer}>
                <Col xs='11' md='6' lg='6' className={classes.whiteSection}>
                    <Row>
                        <Col xs='12' md='12' lg='6' className={classes.centerContentColumn}>
                            <img
                                src={confirmBg}
                                style={{ width: '100%' }}
                                alt='confirm email image'
                            />
                        </Col>
                        <Col xs='12' md='12' lg='6' className={classes.textSection}>
                            <h2 style={{ marginBottom: '30px' }}>Forgot Password?</h2>
                            <p>
                                Please enter your registered email address. We'll send you
                                instructions to help reset your password.
                            </p>
                            <Row style={{ marginBottom: '30px', marginTop: '10px' }}>
                                <Col xs='12' md='12' lg='12'>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label='Your Email Address'
                                        value={emailAdr}
                                        onChange={(e) => {
                                            setEmailAdr(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col xs='12' md='12' lg='12'>
                                    <button
                                        className={classes.verifyBtn}
                                        onClick={forgotPasswordHandler}
                                    >
                                        Send Reset Instructions
                                    </button>
                                </Col>
                            </Row>

                            <p className={classes.notRecived}>
                                Didn't get instructions email?
                            </p>
                            <p className={classes.notRecived}>
                                check your spam folder or{' '}
                                <AutorenewIcon style={{ color: '#1F2144' }} />
                                <span
                                    style={{ color: '#1F2144', cursor: 'pointer' }}
                                    onClick={forgotPasswordHandler}
                                >
                                    Send again
                                </span>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default ForgotPassword;
