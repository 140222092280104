import React from 'react'
import classes from './ProfileNavIconDropDown.module.css'
import profile from "../../../assets/img/jobSeeker.png";

const ProfileNavIconDropDown = (props) => {

    const [hoverIcon, setHoverIcon] = React.useState(false)
    return (
        <div
            color="default"
            className={classes.userProfileToggle}
            style={{
                backgroundColor: hoverIcon ? '#eee' : '#F8F9FF',
                borderRadius: '20px'
            }}
            onMouseEnter={() => setHoverIcon(true)} onMouseLeave={() => { !props.isOpen && setHoverIcon(false) }}>
            <div style={{
                display: "flex", justifyContent: 'space-between', alignItems: 'center'
            }}>
                <div className={classes.avatar}
                    style={{ backgroundImage: `url(${props.user.avatar || profile})` }}>
                </div>
                <h5 className={classes.navItem + ' ' + classes.userNameText}
                    style={{ color: props.darkMode ? 'white' : 'black' }}>{props.user.name}</h5>
            </div>
        </div>

    )
}

export default ProfileNavIconDropDown
