import React, { useState, useEffect } from "react";
import classes from "./Navbar.module.css";
import logo from "../../assets/img/logo.png";
import { Link, NavLink } from "react-router-dom";
import MessageItem from "./Messages/MessageItem";
import NotificationItem from "./Notifications/NotificationItem";
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    Dropdown,
} from "reactstrap";
import NavIconDropDown from "./NavIconDropDown/NavIconDropDown";
import ProfileNavIconDropDown from "./ProfileNavIconDropDown/ProfileNavIconDropDown";
import arrowIcon from "../../assets/img/down.svg";
import GlobalContext from "../../contexts/GlobalContext";
// import MessageList from "../Messages/MessageList";
// import NotificationList from "../Notifications/NotificationList";
import PopupLogin from '../../pages/Login/LoginPopup/PopupLogin';


import axios from "axios";
const IndexNavbar = (props) => {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [openedDropDownUser, setOpenedDropDownUser] = React.useState(false);
    const [darkMode, setDarkMode] = React.useState(false);
    const context = React.useContext(GlobalContext);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [messages, setMessages] = useState([]);
    useEffect(() => {
        getMessages();
    }, []);

    const getMessages = () => {
        axios.get("https://randomuser.me/api/?results=20").then((response) => {
            let newMessages = response.data.results.map((result) => {
                return {
                    photo: result.picture.large,
                    name: `${result.name.first} ${result.name.last}`,
                    text: "Hello world! This is a long message that needs to be truncated.",
                    time: "14 sec ago",
                };
            });
            setMessages([...messages, ...newMessages]);
        });
    };

    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () => {
        let newNotifications = [
            {
                id: 1,
                photo: <i className="fas fa-user-friends"></i>,
                name: "New Customers",
                text: "5 new users",
                time: "14 sec ago",
            },
            {
                id: 2,
                photo: <i className="far fa-comment-dots"></i>,
                name: "New Message",
                text: "7 new messages",
                time: "30 sec ago",
            },
            {
                id: 3,
                photo: <i className="far fa-calendar-alt"></i>,
                name: "New event",
                text: "new event has been opened",
                time: "32 sec ago",
            },
            {
                id: 4,
                photo: <i className="fas fa-user-friends"></i>,
                name: "New Customers",
                text: "5 new users",
                time: "14 sec ago",
            },
            {
                id: 5,
                photo: <i className="far fa-comment-dots"></i>,
                name: "New Message",
                text: "7 new messages",
                time: "30 sec ago",
            },
            {
                id: 6,
                photo: <i className="far fa-calendar-alt"></i>,
                name: "New event",
                text: "new event has been opened",
                time: "32 sec ago",
            },
        ];

        setNotifications([...notifications, ...newNotifications]);
    };

    return (
        <div style={{ marginBottom: "60px" }}>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar
                className={"fixed-top " + classes.navbar}
                expand="lg"
                style={{
                    backgroundColor: context.user ? "white" : "#F8F9FF", boxShadow: 'inset rgba(255, 255, 255, 0.1) 0px 2px 0px,inset rgba(255, 255, 255, 0.04) 0px 0px 0px 2px,rgba(0, 0, 0, 0.25) 0px 2px 10px'
                }}
            >
                <Container style={{ display: "flex", alignItems: "center" }}>
                    <div className="navbar-translate">
                        <NavbarBrand to={"/home"} tag={Link}>
                            <img
                                className={classes.navLogo}
                                src={logo}
                                alt="Smart Interact"
                            />
                        </NavbarBrand>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            paddingBottom: "20px",
                        }}
                    >
                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <i
                                className="fas fa-bars"
                                style={{ color: darkMode ? "white" : "black" }}
                            ></i>
                        </button>
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                        style={{ paddingBottom: "10px" }}
                    >
                        <Nav navbar>
                            {context.user && (
                                <React.Fragment>
                                    <NavItem
                                        className={classes.navItem}
                                        style={{ paddingBottom: "7px" }}
                                        tag={Link}
                                        to={`#`}
                                    >
                                        <ProfileNavIconDropDown
                                            user={context.user}
                                        ></ProfileNavIconDropDown>
                                    </NavItem>

                                </React.Fragment>
                            )}

                            {!context.user &&
                                <NavItem className={classes.navItem} tag={Link} to='signup'>
                                    <button style={{ backgroundColor: 'transparent', border: 'none', padding: '10px 20px', }} >
                                        Register
                                    </button>

                                </NavItem>}

                            {!context.user &&
                                <NavItem className={classes.navItem} >
                                    <button style={{ backgroundColor: '#56BC80', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }} onClick={toggle} >
                                        Login
                                    </button>
                                    <PopupLogin toggle={toggle} modal={modal}></PopupLogin>

                                </NavItem>}
                            {context.user && (
                                <NavItem className={classes.navItem}>
                                    <NavIconDropDown
                                        title={""}
                                        toggle={() => {
                                            setOpenedDropDownUser(!openedDropDownUser);
                                        }}
                                        isOpen={openedDropDownUser}
                                        darkMode={darkMode}
                                        icon={arrowIcon}
                                    >
                                        <Dropdown
                                            className={classes.menuActive}
                                            style={{
                                                left: "-80px !important",
                                                display: openedDropDownUser ? "block" : "none",
                                                backgroundColor: darkMode ? "#242526" : "white",
                                                color: darkMode ? "#242526" : "white",
                                            }}
                                        >
                                            <ul className={classes.menuUL}>
                                                <li
                                                    className={classes.menuLi}
                                                    onClick={() => {
                                                        context.logoutHandler();
                                                        console.log("logout");
                                                    }}
                                                >
                                                    <h5 className={classes.menuLiA} style={{ color: 'red' }} >
                                                        <i
                                                            className="fas fa-sign-out-alt"
                                                            style={{
                                                                // paddingRight: "5px",
                                                                // fontSize: "15px",
                                                                color: darkMode ? "white" : "black",
                                                                fontSize: "20px",
                                                                border: " 1px solid #edf1f4",
                                                                borderRadius: "23px",
                                                                padding: "5px",
                                                                backgroundColor: "#edf1f4",
                                                                marginRight: "10px",
                                                            }}
                                                        ></i>
                                                        Logout
                                                    </h5>
                                                </li>
                                            </ul>
                                        </Dropdown>
                                    </NavIconDropDown>
                                </NavItem>
                            )}
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default IndexNavbar;
