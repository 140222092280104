import React, { useEffect } from 'react';
import * as qp from 'query-parse';
import axios from '../../utils/axios';

const Confirmer = (props) => {
  const paramObj = qp.toObject(
    props.location.search.slice(1, props.location.search.length)
  );
  useEffect(() => {
    axios
      .get(`/user/verify/${props.match.params.token}`)
      .then((res) => {
        console.log(res);
        props.history.push('/login');
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  return <div></div>;
};
export default Confirmer;
