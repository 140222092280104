import React, { useEffect } from 'react';
import * as qp from 'query-parse';
import axios from '../../utils/axios';
import { Row, Col, Container } from 'reactstrap';
import classes from './ResetPassword.module.css';
import logo from '../../assets/img/logo.png';
import confirmBg from '../../assets/img/resetBg.jpg';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#beb5b5',

            borderWidth: 2,
            borderRadius: 30,
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00dfc0',
        },

        '& .MuiOutlinedInput-input': {
            color: '#beb5b5',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#00dfc0',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },
        '& .MuiInputLabel-outlined': {
            color: '#B5B5BE',
        },

        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#00dfc0',
        },
    },

    label: {
        backgroundColor: 'white',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: -6,
    },
}));
const ForgotPassword = (props) => {
    const classesMat = useStyles();

    const [password, setPassword] = React.useState('');
    const [emailAdr, setEmailAdr] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [error, setError] = React.useState(0);
    const validatePassword = (password) => {
        // return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
        //   password
        // );
        return password.length >= 8;
    };
    const paramObj = qp.toObject(
        props.location.search.slice(1, props.location.search.length)
    );
    const queryParams = new URLSearchParams(props.location.token);
    useEffect(() => {
        if (props.match.params.token !== 'reset') {
            axios
                .get(`/user/verify/${props.match.params.token}`)
                .then((res) => {
                    setEmailAdr(res.data.email);
                    props.history.replace('/reset-password/reset');
                })
                .catch((err) => {
                    console.log({ err });
                });
        } else {
            props.history.replace('/forgot');
        }
    }, []);
    const resetPasswordHandler = () => {
        if (!validatePassword(password)) {
            setError(1);
        } else if (password !== confirmPassword) {
            setError(2);
        } else {
            axios
                .patch('/user/reset-password', {
                    email: emailAdr,
                    password: password,
                })
                .then((res) => {
                    console.log(res);
                    Swal.fire({

                        icon: 'success',
                        title: 'Your Password changed successfully',
                        showConfirmButton: false,

                    });
                    props.history.push('/login');
                })
                .catch((err) => {
                    Swal.fire({

                        icon: 'error',
                        title: 'Something went wrong!',
                        showConfirmButton: false,

                    });
                    console.log({ err });
                });
            setError(0);
        }
    };
    return (
        <Container fluid>
            <Row>
                <Col
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                        color: '#00dfc0',
                    }}
                >
                    <ArrowBackIcon />
                    <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            props.history.push('/login');
                        }}
                    >
                        Back to login screen
                    </p>
                </Col>
            </Row>
            <Row className={classes.logoContainer}>
                <Col xs='7' md='4' lg='2'>
                    <img src={logo} alt='logo' className={classes.logo} />
                </Col>
            </Row>
            <Row className={classes.contentContainer}>
                <Col xs='11' sm='7' md='6' lg='6' className={classes.whiteSection}>
                    <Row>
                        <Col
                            xs='12'
                            md='12'
                            lg='6'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={confirmBg}
                                style={{ width: '100%' }}
                                alt='confirm email image'
                            />
                        </Col>
                        <Col xs='12' md='12' lg='6' className={classes.textSection}>
                            <h2 style={{ marginBottom: '30px' }}>Reset your Password?</h2>
                            <Row style={{ marginTop: '10px' }} className={classesMat.root}>
                                <Col xs='12' md='12' lg='12'>
                                    <TextField
                                        InputLabelProps={{
                                            classes: {
                                                root: classesMat.label,
                                            },
                                        }}
                                        style={{ width: '100%' }}
                                        label='New Password'
                                        type='password'
                                        margin='normal'
                                        required
                                        autoComplete='current-password'
                                        variant='outlined'
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className={classesMat.root}>
                                <Col xs='12' md='12' lg='12'>
                                    <TextField
                                        InputLabelProps={{
                                            classes: {
                                                root: classesMat.label,
                                            },
                                        }}
                                        style={{ width: '100%' }}
                                        label='Confirm Password'
                                        type='password'
                                        margin='normal'
                                        required
                                        autoComplete='current-password'
                                        variant='outlined'
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                        }}
                                    />
                                    {error === 2 && (
                                        <p className={classes.errorTag}>*Password does not match</p>
                                    )}
                                    {error === 1 && (
                                        <p className={classes.errorTag}>
                                            *Choose a stronger password please
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '20px' }}>
                                <Col xs='12' md='12' lg='12'>
                                    <button
                                        className={classes.verifyBtn}
                                        onClick={resetPasswordHandler}
                                    >
                                        Reset your password
                                    </button>
                                </Col>
                            </Row>

                            <p className={classes.notRecived}>
                                Minimum 8 characters. Must contain upper and lowercase, numbers
                                and symbols.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default ForgotPassword;
