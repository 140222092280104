import AppContext from './contexts/AppContext';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import IndexNavbar from './components/Navbar/Navbar';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Meet from './pages/Meet/Meet'
import ConfirmAccount from './pages/ConfirmAccount/ConfirmAccount';
import Confirmer from './pages/ConfirmAccount/Confirmer';
import ForceConfirmation from './pages/ConfirmAccount/ForceConfirmation/ForceConfirmation';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ExternConnection from './pages/Login/ExternConnection/ExternConnection';
import MeetHome from './pages/MeetHome/MeetHome';


const App = () => {
    return (
        <AppContext>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/login' render={() => { }} />
                    <Route exact path='/signup' render={() => { }} />
                    <Route exact path='/confirm' render={() => { }} />
                    <Route exact path='/confirmer' render={() => { }} />
                    <Route exact path='/confirmation/:token' render={() => { }} />
                    <Route exact path='/reset-password/:token' render={() => { }} />
                    <Route exact path='/forgot' render={() => { }} />
                    <Route exact path='/extern-onnection' render={() => { }} />
                    <Route exact path='/meet/:meetId' render={() => { }} />
                    <Route exact path='/*' render={(props) => <IndexNavbar {...props} />} />
                </Switch>
                <Switch>
                    <Route exact path='/' render={(props) => <MeetHome {...props} />} />
                    <Route path='/extern-onnection' render={(props) => <ExternConnection {...props} />} />
                    <Route exact path='/forgot' render={(props) => <ForgotPassword {...props} />} />
                    <Route exact path='/confirmation/:token' render={(props) => <Confirmer {...props} />} />
                    <Route exact path='/reset-password/:token' render={(props) => <ResetPassword {...props} />} />
                    <Route path='/confirm' render={(props) => <ConfirmAccount {...props} />} />
                    <Route path='/confirmer' render={(props) => <ForceConfirmation {...props} />} />
                    <Route path='/login' render={(props) => <Login {...props} />} />
                    <Route path='/signup' render={(props) => <Signup {...props} />} />
                    <Route path='/meet/:meetId' render={(props) => <Meet {...props} />} />
                    <Route path='/meet' render={(props) => <MeetHome {...props} />} />

                    <Redirect to='/' />
                </Switch>
            </BrowserRouter>
        </AppContext>
    );
};

export default App;
