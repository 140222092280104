import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'reactstrap';
import classes from './PopupLogin.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import logo from '../../../assets/img/logo.png';
import jobSeeker from '../../../assets/img/jobSeeker.png';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Checkbox from '@material-ui/core/Checkbox';
import FacebookIcon from '@material-ui/icons/Facebook';
import Swal from 'sweetalert2';
import localStorageService from '../../../utils/localStorageService';
import axios from '../../../utils/axios';
import { Link, useHistory } from 'react-router-dom';

const PopupLogin = (props) => {
    const history = useHistory();
    const { toggle, modal } = props;
    const [rememberMe, setRememberMe] = React.useState(localStorage.getItem('rememberMe') === 'true');
    const [identifier, setIdentifier] = React.useState(rememberMe ? localStorage.getItem('user') : '');
    const [password, setPassword] = React.useState('');


    const loginHandler = () => {
        axios
            .post('/user/login', {
                identifier,
                password,
            })
            .then((res) => {
                localStorageService.setToken(res.data.accessToken);
                console.log(res.data);
                localStorage.setItem('rememberMe', rememberMe);
                localStorage.setItem('user', rememberMe ? identifier : '');
                window.location.href = '/home';
            })
            .catch((err) => {
                console.log({ err });
                Swal.fire({

                    icon: 'error',
                    title: 'Please check your identifier and password then try again',
                    showConfirmButton: false,

                });
            });
    };
    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            centered={true}
            size='lg'
            contentClassName={classes.myModal}
        >
            <Row className={classes.container}>
                <Col lg='6' className={classes.leftSide}>
                    <Row className={classes.topSection}>
                        <Col xs='12' md='4' lg='4'>
                            <img src={logo} alt='logo' className={classes.logo} />
                        </Col>
                        <Col xs='12' md='4' lg='4'>
                            <button className={classes.registerButton} onClick={() => { history.push('/signup') }}>
                                Create Account
                            </button>
                        </Col>
                    </Row>
                    <Row className={classes.bottomSection}>
                        <Col xs='12' md='9' lg='9'>
                            <img
                                src={jobSeeker}
                                className={classes.jobSeekerImg}
                                alt='jobSeeker'
                            />
                        </Col>
                    </Row>
                    <Row className={classes.bottomSection}>
                        <Col xs='12' md='9' lg='9'>
                            <h5 className={classes.jobSeekerTitle}>
                                The Easiest Way to Get Your New Opportunities
                            </h5>
                        </Col>
                    </Row>
                </Col>
                <Col xs='12' md='12' lg='6' className={classes.rightSide}>
                    <button onClick={toggle} className={classes.closeButton}>
                        <FontAwesomeIcon icon={faTimes} color='white' />
                    </button>
                    <Row>
                        <Col>
                            <h5 className={classes.title}>Log In To Your Account</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                id='input-with-icon-textfield1'
                                label='Email or Phone'
                                style={{ width: '90%' }}
                                value={identifier}
                                onChange={(e) => setIdentifier(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <MailOutlineIcon color='disabled' />
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: classes.resize,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                id='input-with-icon-textfield2'
                                type='Password'
                                label='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{
                                    width: '90%',
                                    marginTop: '10px',
                                    marginBottom: '25px',
                                }}
                                //className={classes.emailInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <LockOpenIcon color='disabled' />
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: classes.starsSize,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={classes.rememberMeCol}>
                        <Col xs='12' md='7' lg='7' className={classes.rememberMe}>
                            <Checkbox
                                color='primary'
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <p className={classes.rememberText}>Remember Me</p>
                        </Col>
                        <Col
                            xs='12'
                            md='5'
                            lg='5'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {/*  eslint-disable-next-line */}
                            <Link to='./forgot' className={classes.forgot}>
                                Forgot Password
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button className={classes.loginButton} onClick={() => loginHandler()}>Login</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={classes.separator}>
                            <p className={classes.instantLogin}>
                                <span>Instant Login</span>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button className={classes.googleButton}>
                                <FontAwesomeIcon
                                    icon={faGoogle}
                                    className={classes.iconGoogle}
                                    color='white'
                                    size='lg'
                                />
                                Continue with Google
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button className={classes.fbButton}>
                                <FacebookIcon className={classes.iconFacebook}></FacebookIcon>
                                Continue with Facebook
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>

    );
};

export default PopupLogin;
