import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import classes from './ConfirmAccount.module.css';
import logo from '../../assets/img/logo.png';
import confirmBg from '../../assets/img/confirmBg.jpg';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import * as qp from 'query-parse';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';

const ConfirmAccount = (props) => {
    const paramObj = qp.toObject(
        props.location.search.slice(1, props.location.search.length)
    );
    const verifEmailHandler = () => {
        axios
            .post(`${process.env.REACT_APP_SI_API}/public/api/v1/send-activation-link`, {
                email: paramObj.email,
            })
            .then((res) => {
                console.log(res);
                Swal.fire({

                    icon: 'success',
                    title: 'Confirmation email sent successfully!',
                    showConfirmButton: false,

                });
            })
            .catch((err) => {
                console.log({ err });
                Swal.fire({

                    icon: 'error',
                    title: 'Please enter valid email!',
                    showConfirmButton: false,

                });
            });
    };
    return (
        <Container fluid>
            <Row className={classes.logoContainer}>
                <Col xs='7' md='4' lg='2'>
                    <img src={logo} alt='logo' className={classes.logo} />
                </Col>
            </Row>
            <Row className={classes.contentContainer}>
                <Col xs='11' sm='7' md='6' lg='6' className={classes.whiteSection}>
                    <Row>
                        <Col
                            xs='12'
                            md='12'
                            lg='6'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={confirmBg}
                                style={{ width: '100%' }}
                                alt='confirm email image'
                            />
                        </Col>
                        <Col xs='12' md='12' lg='6' className={classes.textSection}>
                            <h2 style={{ marginBottom: '30px' }}>Verification link sent!</h2>
                            <p style={{ marginBottom: '30px' }}>
                                We emailed a confirmation link <b style={{ display: 'block', textOverflow: 'ellipsis', maxWidth: '300px', overflow: 'hidden' }}>{paramObj.email}</b> check
                                your email for a link to verify your account.
                            </p>

                            <p className={classes.notRecived}>
                                Didn't get confirmation email?
                            </p>
                            <p className={classes.notRecived}>
                                check your spam folder or{' '}
                                <AutorenewIcon style={{ color: '#1F2144' }} />
                                <span
                                    style={{ color: '#1F2144', cursor: 'pointer' }}
                                    onClick={verifEmailHandler}
                                >
                                    Send again
                                </span>
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default ConfirmAccount;
