import React, {useContext} from 'react';
import {Col, Container, Row} from 'reactstrap';
import classes from './Login.module.css';
import logo from '../../assets/img/logo.png';
import TextField from '@material-ui/core/TextField';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import axios from '../../utils/axios';
import localStorageService from '../../utils/localStorageService';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import GlobalContext from "../../contexts/GlobalContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#696974',
            borderWidth: 2,
            borderRadius: 10,
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1F2144',
        },
        '& .MuiOutlinedInput-input': {
            color: '#696974',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#1F2144',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },
        '& .MuiInputLabel-outlined': {
            color: '#696974',
        },

        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#1F2144',
        },
    },

    label: {
        backgroundColor: 'white',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: -6,
    },
}));

const Login = (props) => {
    const context = useContext(GlobalContext)
    const classesMat = useStyles();
    const [rememberMe, setRememberMe] = React.useState(localStorage.getItem('rememberMe') === 'true');
    const [identifier, setIdentifier] = React.useState(rememberMe ? localStorage.getItem('user') : '');
    const [password, setPassword] = React.useState('');


    const loginHandler = () => {
        axios
            .post(`${process.env.REACT_APP_SI_API}/public/api/v1/login`, {
                email: identifier,
                password,
            })
            .then((res) => {
                localStorageService.setToken(res.data.accessToken);
                context.setUser(res.data.userData);
                localStorage.setItem('rememberMe', rememberMe);
                localStorage.setItem('user', rememberMe ? identifier : '');
                window.location.href = '/';
            })
            .catch((err) => {
                console.log({ err });
                Swal.fire({

                    icon: 'error',
                    title: 'Please check your identifier and password then try again',
                    showConfirmButton: false,

                });
            });
    };
    return (
        <Container fluid>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Col xs="12" sm="6" md="3">
                    <Row className={classes.container}>
                        <Col

                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <img src={logo} alt='logo' className={classes.logo} />
                        </Col>
                    </Row>
                    <Row className={classes.main}>
                        <Col className={classes.block}>
                            <Row>
                                <Col className={classes.container}>
                                    <h1 className={classes.title}>Sign In</h1>
                                </Col>
                            </Row>
                            <Row className={classesMat.root}>
                                <Col xs='12' style={{ display: 'flex', justifyContent: 'center' }}>
                                    <TextField
                                        InputLabelProps={{
                                            classes: {
                                                root: classesMat.label,
                                            },
                                        }}
                                        variant='outlined'
                                        margin='normal'
                                        label='Your Email / Phone'
                                        className={classes.field}
                                        value={identifier}
                                        onChange={(e) => setIdentifier(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row className={classesMat.root}>
                                <Col
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TextField
                                        InputLabelProps={{
                                            classes: {
                                                root: classesMat.label,
                                            },
                                        }}
                                        className={classes.field}
                                        id='outlined-password-input'
                                        label='Password'
                                        type='password'
                                        autoComplete='current-password'
                                        variant='outlined'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row className={classes.checkbox} style={{ justifyContent: 'flex-end' }}>
                                <Col className={classes.forgotPassColumn}>
                                    <Link to='./forgot' className={classes.forgot}>
                                        Forgot Password
                                    </Link>
                                </Col>
                            </Row>
                            <Row className={classesMat.root}>
                                <Col xs='12' style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        className={classes.loginBtn}
                                        onClick={() => loginHandler()}
                                    >
                                        Login
                                    </button>
                                </Col>
                            </Row>
                            <Row className={classesMat.root}>
                                <Col xs='12' style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        className={classes.signupBtn}
                                        onClick={() => props.history.push('/signup')}
                                    >
                                        Create New Account
                                    </button>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
