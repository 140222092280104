import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classes from './ExternConnection.module.css';
import logo from '../../../assets/img/logo.png';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#beb5b5',

            borderWidth: 2,
            borderRadius: 30,
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00dfc0',
        },

        '& .MuiOutlinedInput-input': {
            color: '#beb5b5',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#00dfc0',
            paddingLeft: '1em',
            paddingBottom: '15px',
            paddingTop: '12px',
        },
        '& .MuiInputLabel-outlined': {
            color: '#B5B5BE',
        },

        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#00dfc0',
        },
    },

    label: {
        backgroundColor: 'white',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: -6,
    },
}));

const ExternConnection = () => {
    const classesMat = useStyles();
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [error, setError] = React.useState(0);


    const validatePassword = () => {
        // return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
        //   password
        // );
        return password.length >= 8;
    };
    const externConnectionHandler = () => {
        if (!validatePassword()) {
            setError(1);
        } else if (password !== confirmPassword) {
            setError(2);
        } else {

            setError(0);
        }
    };
    return (
        <Container fluid >
            <Row className={classes.logoContainer}>
                <Col xs='7' md='4' lg='2'>
                    <img src={logo} alt='logo' className={classes.logo} />
                </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Col lg='3' className={classes.whiteSection}>
                    <Row >
                        <Col style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginTop: '30px' }}>
                            <h5 style={{ fontWeight: '600' }}>Choose your password</h5>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }} className={classesMat.root}>
                        <Col xs='12' md='12' lg='12'>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classesMat.label,
                                    },
                                }}
                                style={{ width: '100%' }}
                                label='New Password'
                                type='password'
                                margin='normal'
                                required
                                autoComplete='current-password'
                                variant='outlined'
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={classesMat.root}>
                        <Col xs='12' md='12' lg='12'>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classesMat.label,
                                    },
                                }}
                                style={{ width: '100%' }}
                                label='Confirm Password'
                                type='password'
                                margin='normal'
                                required
                                autoComplete='current-password'
                                variant='outlined'
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                            {error === 2 && (
                                <p className={classes.errorTag}>*Password does not match</p>
                            )}
                            {error === 1 && (
                                <p className={classes.errorTag}>
                                    *Choose a stronger password please
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col xs='12' md='12' lg='12'>
                            <button
                                className={classes.verifyBtn}
                                onClick={externConnectionHandler}
                            >
                                Create Account
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container>)
};
export default ExternConnection;