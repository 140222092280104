import React from 'react'
import classes from './UserPreviewCard.module.css'
import * as vad from 'voice-activity-detection'
import WaveVisualizer from '../WaveVisualizer/WaveVisualizer';
const UserPreviewCard = (props) => {

    const ref = React.useRef();
    const [hoverCard, setHoverCard] = React.useState(false)
    const [soundActivity, setSoundActivity] = React.useState(0)
    const [minimizedCard, setMinimizedCard] = React.useState(false)
    React.useEffect(() => {
        if (ref.current)
            ref.current.srcObject = props.videoStream;
    }, [props.isAudioOn, props.videoStream]);

    React.useEffect(() => {
        const audioContext = new AudioContext();
        const voiceDectionOptions = {
            onUpdate: (activityValue) => {
                setSoundActivity(activityValue)
            }

        };
        vad(audioContext, props.audioStream, voiceDectionOptions);


    }, [props]);

    if (minimizedCard)
        return (

            <div className={classes.minimizedMainContainer}>
                {hoverCard && <div className={classes.minimizedControlContainer}>
                    {!props.isAudioOn ?
                        <div className={classes.menuItem}>
                            <i className={`fas fa-microphone-slash ${classes.microIcon}`}></i>
                        </div> :
                        <div className={classes.menuItem}>
                            <WaveVisualizer soundActivity={soundActivity} />
                        </div>
                    }
                    <div className={classes.menuItem}>
                        <i className={` ${props.isVideo ? 'fas fa-video' : `fas fa-video-slash`} ${classes.controlIcon}`} ></i>
                    </div>
                    <div>
                        <h5 className={classes.youText}>
                            You
                        </h5>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                        <div className={classes.menuControlItem} onClick={() => setMinimizedCard(false)}>
                            <i className={`fa-solid fa-arrows-up-down-left-right ${classes.controlIcon}`} ></i>
                        </div>
                    </div>
                </div>}
            </div>
        )
    else
        return (
            <div className={classes.mainContainer} onMouseEnter={() => setHoverCard(true)} onMouseLeave={() => setHoverCard(false)}>
                <div className={classes.videoContainer} style={{ display: !props.isVideoOn ? 'none' : 'block' }}>
                    <video className={classes.video} muted playsInline autoPlay ref={ref} ></video>
                </div>
                {!props.isVideoOn && <div className={classes.mainUserInfoContainer}>

                    {hoverCard && <div className={classes.controlContainer}>
                        <div className={classes.menuControlItem} onClick={() => setMinimizedCard(true)}>
                            <i className={`fa-solid fa-minimize ${classes.controlIcon}`} ></i>
                        </div>
                        <div className={classes.menuControlItem} onClick={props.pinUserFunction}>
                            <i className={`fas fa-thumbtack ${classes.controlIcon}`} ></i>
                        </div>
                        <div className={classes.menuControlItem} onClick={props.unPinUserFunction}>
                            <i className={`fa-solid fa-border-all ${classes.controlIcon}`} ></i>
                        </div>
                    </div>}

                    <img src={props.image} className={classes.userImage} />

                </div>
                }
                {!props.isAudioOn &&
                    <div className={classes.centerItemIconBlock}>
                        <i className={`fas fa-microphone-slash ${classes.microIcon}`}></i>
                    </div>
                }
            </div>
        )
}



export default UserPreviewCard
