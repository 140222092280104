import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';


const CustomTooltip = (props) => {

    const useStyles = makeStyles((theme) => ({
        tooltip: {
            fontSize: props.fontSize || '12px',
            backgroundColor: props.darkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
            color: props.darkMode ? theme.palette.common.black : theme.palette.common.white,
        },
        arrow: {
            color: props.darkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
        },
    }));

    const toolTipClasses = useStyles();

    return (
        <Tooltip arrow={props.arrow} title={props.title} classes={toolTipClasses} placement={props.placement}>
            {props.children}
        </Tooltip>)

}

export default CustomTooltip