import React from 'react';
import GlobalContext from './GlobalContext';
import LocalStorageService from '../utils/localStorageService';
import io from 'socket.io-client';
import axios from "../utils/axios";

const AppContext = (props) => {
    const [user, setUser] = React.useState(null)
    const [loadingUser, setLoadingUser] = React.useState(true)
    const socket = React.useState(io(process.env.REACT_APP_MEET_API))[0];

    React.useEffect(() => {

        const init = async () => {
            try {
                const accessToken = LocalStorageService.getAccessToken()
                const user = await axios.get(`${process.env.REACT_APP_SI_API}/private/api/v1/connect`)

                if (accessToken && user && user.data) {
                    if (!user.data.accountVerified) {
                        LocalStorageService.clearToken();
                        window.location.href = '/confirmer';
                    }
                    setUser(user.data)
                    socket.emit('connectuser', accessToken)
                    setLoadingUser(false)
                } else {
                    setLoadingUser(false)
                }
            } catch (e) {
                setLoadingUser(false)
            }
        }

        init()

    }, [])


    const logoutHandler = () => {
        LocalStorageService.clearToken()
        window.location.href = '/login'
    }


    return (
        <GlobalContext.Provider value={{
            user,
            setUser,
            logoutHandler,
            socket
        }}>
            {!loadingUser && props.children}
        </GlobalContext.Provider>
    );
};

export default AppContext;
